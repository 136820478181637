import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row } from "reactstrap";

const TermsCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container fluid>
      <Row>
        <Header />
        <div className="topDivider">
          <p className={`PageTitleText light p-2 m-0 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30'}`}>Terms & Conditions</p>
        </div>
      </Row>
      <Row>
        <div className={`py-3 fs-16 ${window.innerWidth <= 768 ? 'px-3 text-center' : 'px-5'}`} style={{ borderBottom: "1px solid #d1d1d1" }}>
          <p>Welcome to Hindu Shadi Connect, hereafter referred to as "the platform. This platform encompasses both the website and the licensed mobile application developed to access its services. It is owned and operated by Ranu Jain, the sole proprietor. By using the services and products offered through this platform, you agree to the following terms and conditions:</p>
          <p>These terms and conditions may be periodically updated. If you disagree with these terms or fail to meet and comply with them, we advise refraining from accessing the platform and its associated services and products.
          </p>

          <p>Under the definition provided in sub-clause (w) of Section 2 of the Information Technology Act, 2000, the platform functions as an intermediary. As a visitor to the platform, you are granted a nonexclusive, limited license to access and use the content and services made available, provided that you adhere to the stipulations outlined in these Terms and Conditions. This license will hereafter be referred to as the "License."</p>
          <p> All individuals, whether they have made any payments for accessing services on the platform or not, are recognized as users. This includes both registered and unregistered users who simply browse the platform.</p>
          <p>Through the affirmative actions of a user engaging with the platform, the user hereby consents to be legally bound by the subsequent set of terms and conditions ("Terms"). If a user finds any of these terms and conditions unacceptable, they are advised to refrain from accessing or using the platform.</p>
          <p>
            1. The ownership and operation of the platform rest with Ranu Jain and Hindu Shadi Connect, hereinafter referred to as the "Company. This term encompasses the company’s subsidiaries, affiliates, executives, officers, agents, attorneys, accountants, and any other individuals entrusted with responsibilities related to the company’s affairs. The registered office is currently situated at Civil Ward NO 10, In Front Of Agrawal School, Teen Gulli Chouraha,

Damoh, Madhya Pradesh - 470661.</p>
          <p>
            2. The primary purpose of the platform is to serve as an initial point of contact and provide information for users who genuinely intend to form a matrimonial alliance 2. The primary purpose of the platform is to serve as an initial point of contact and provide information for users who genuinely intend to form a matrimonial alliance ("purpose"). It is important to clarify that the platform does not claim to operate as a marriage or business bureau, nor does it function as a dating or contact website. </p>
          <p>3. The company operates under the presumption that each user accessing the platform has a sincere intention to pursue marriage or a matrimonial alliance and not for any other purpose. Under no circumstances will the company be held responsible for losses or damages arising from any user’s interaction with the platform, use of services offered, content posted on the platform, or transmission to other users. The sharing of matrimonial profiles through the platform should not be misconstrued as a matrimonial proposal or recommendation endorsed by the company. Any losses or damages incurred by individuals due to matrimonial relations formed through the company’s services are beyond the company’s liability.</p>
          <h6>4. Collection and Use of User Data</h6>
          <p>We gather a range of information, such as email addresses, addresses, names, phone numbers, birthdates, ages, genders, cultural backgrounds, appearances, religions, occupations, preferences, lifestyle details, and general geographical locations ("User Data"), from our users. These data enable users to create profiles on our platform. In addition, we collect specific health-related data, including physical and mental disabilities. The collected user data is entered into a specialized program designed to match profiles and provide users with matches based on their chosen criteria. The display of user data requires the user’s prior consent. We do not request financial information, such as credit card or bank account numbers. To access the platform and create profiles, users must provide the necessary user data. By using the platform, users agree to have their user data displayed to other platform users.
            Users grant us permission to utilize their activity and interaction information on the platform for the display of ads, offers, and sponsored content without any charges. We use this data to provide relevant recommendations and suggestions to our users.</p>
          <h6>5. User Data Submission and Display </h6>
          <p>Users can upload their own user data or use the Hindu Shadi Connect offline centers listed on the platform. At our discretion, we may feature some or all of a user’s submitted information on the platform across various media, such as print media, community associations, websites, television shows, DTH/IPTV services, and mobile war sites, at no extra cost to users. The company holds no liability for the use or publicity of such information.</p>
          <h6> 6. Responsible Platform Use</h6>
          <p>Users commit not to duplicate, download, publish, modify, or distribute platform content unless explicitly authorized by the company in writing. Establishing deep links or connections to specific platform pages requires prior written consent from the company.</p>
          <h6>7. Personal and noncommercial use </h6>
          <p>The platform is intended for personal use. Creating derivative works with commercial intent from platform content is strictly prohibited without prior written consent from the company. 8. Ensuring Platform Integrity To safeguard the integrity of Hindu Shadi Connect, we employ technological measures aimed at preventing content crawling or scraping by robots, scripts, or programs. Users are expected to respect and adhere to these measures, refraining from bypassing them in any way. </p>

          <p>8. Content Reliability and User Reliance It's important to note that advice, counseling, recommendations, and information available on the platform, including user-generated content, may not always be accurate, true, or reliable. Any actions taken based on such information are entirely at the user's own risk. Users understand that access to platform content is provided "as-is" and "as-available. Those who choose to use this information are advised to conduct their own due diligence at their own cost and effort. The company does not endorse establishing contact or interactions solely based on user profiles or photographs. Any such actions are the sole responsibility of the user. The company cannot be held liable for its reliance on platform information, which serves as a general indicator of the related scenario.
          </p>
          <p>9. Authenticity of Information and User Diligence The company does not verify, endorse, or guarantee the accuracy of information or claims within matrimonial listings or other content on the platform. While the company does not endorse matches and profiles, users should assume that the information provided is intended for creating matrimonial alliances based on user-set filter criteria. Users should exercise diligence and caution when using information from the platform, understanding that the content in matrimonial listings is posted directly by platform users without company verification. Users are strictly prohibited from uploading content that:a) belongs to someone else without permission; b) is harmful, offensive, defamatory, obscene, or illegal; c) violates intellectual property rights or laws; d) deceives or misleads others; e) contains viruses or malicious code; f) threatens public order or relations with foreign states. The company reserves the right to terminate or restrict user access for any violation of these terms.
          </p>
          <p>10. User Data and Platform Usage The company offers an online service for users to create unique profiles, with optional personal information displayed on the platform. User data is not shared with others without the user’s explicit consent. While the company takes precautions, it is not responsible for unauthorized copying, printing, publishing, forgery, manipulation, or misuse of displayed user data.</p>
          <p>11. User Liability for Listings By posting a matrimonial, business, or personal profile, or any content on the platform, users agree to hold the company harmless against any claims, complaints, or damages arising from such listings.</p>
          <p> 12. Truthful and Responsible Use of the Platform Users must provide accurate and truthful information when using the platform. When creating a profile, users commit to keeping their information current. The accuracy or reliability of any member or visitor profile, advice, opinion, statement, or information displayed through our service is not endorsed by the company. The company, its owners, staff, or agents are not responsible for any direct or indirect consequences of online content, such as illicit material or offensive content. We do not monitor user conduct and, therefore, cannot be held responsible for users who misuse the online service to harass, abuse, or threaten others.
          </p>
          <p> 13. Removal of Content and Registration Cancellation The company retains the right to delete content, messages, photos, and profiles, or cancel the registration or membership of a user based on complaints or its own discovery. This action can be taken at the company’s sole discretion, potentially terminating services and forfeiting registration fees. In cases where it appears that a user listed their profile without genuine intention or changed their intention afterward, the company reserves the right to remove such profiles and take legal action. Users are not permitted to post threatening, abusive, vulgar, or misleading content, impersonate others, or use the site for commercial purposes. Disruptive behavior or non-compliance with the company’s guidelines will be dealt with accordingly.
          </p>
          <p>14. No Inducement or Persuasion The content on the platform is not intended to induce, persuade, convince, or invite any person to perform actions that violate laws or regulations. Users should be aware that their actions and decisions should conform to applicable laws.
          </p>
          <p>15. User Interactions and Disputes The company cannot be held responsible for interactions or information exchanged between users via email, chat, or other mediums. The company is not a party to disputes between users and will not participate in any litigation arising from such disputes.
          </p>
          <p>16. Platform Content Modification The company reserves the absolute right to modify, amend, or delete any content in matrimonial listings to ensure compliance with placement rules, accuracy, and prevailing laws. If duplicate profiles are detected, the company reserves the right to delete all such profiles. Refusal to consent to physical address verification may result in the placement of a "Not Verified" stamp or profile deletion.</p>

          <p>17. External Links and Third-Party Content The Hindu Shadi Connect platform includes links to external websites, and the content of those sites solely rests with the promoters of those websites. The company neither recommends, authenticates, endorses, verifies, nor certifies these websites or their contents or links. The privacy practices of these external sites are beyond our control. We advise users to review the privacy statements of all visited websites. We also do not endorse or authenticate the authenticity of other online services displayed on the platform, such as contests, astrology, palmistry, etc. Users should exercise caution and responsibility while navigating these external links, recognizing that their connections occur through their own IP and not the platform's IP. The company does not endorse sharing personal information, especially usernames and profile IDs, with such websites.
          </p>
          <p>18. Future Partners and Information Sharing The company may partner with other entities in the future to offer various online services. However, we will not share information or data collected from users with these partners without obtaining prior consent. Users should review the privacy policies of these partners before sharing personally identifiable information. The company is not privy to their privacy practices and cannot be held responsible for their policies.
          </p>
          <p>19. Disclaimer of Liability The company disclaims any liabilities, warranties, or guarantees, express or implied, related to the existence, promotion, contents, links, or discontinuance of the platform. Claims for damages, loss of profits, or other claims arising from platform matters are not sustainable against the company.
          </p>
          <p>20. Technical Issues and Communication The company assumes no responsibility for errors, interruptions, deletions, delays, unauthorized access, or alterations in user communications. Problems with telephone networks, computer systems, servers, online systems, providers, and equipment are beyond our control. We are not responsible for injuries or damages resulting from participating in or downloading materials or information from the platform.</p>
          <p> 21. User Classification and Privileges Users visiting the platform are classified as having incomplete or complete profiles, and they may be free or paid members. The company holds the right to modify privileges, benefits, liabilities, and responsibilities for all types of users based on its discretion. Benefits for free users, including "Express Interest" functionality and viewing contact details, are determined at the company's discretion.</p>
          <p>22. Payment and Refund Policy: Payments for platform services are made in advance and are non-refundable. Refunds, if granted, are at the sole discretion of the company. Server uptime, application functionality, and liability for losses are on a best-effort basis. The company reserves the right to amend disclaimers and terms without notice. Continued usage of the platform constitutes acceptance of the amended terms.</p>

          <p>23. User Responsibility and Confidentiality Users are solely responsible for maintaining password confidentiality and 17. Content Modification for a Better Experience
            At Hindu Shadi Connect, we believe in providing a safe and authentic platform for your matrimonial journey. To ensure that, we have a few guidelines in place:
          </p>
          <p>Content Enhancement: We have the absolute right to modify, amend, or delete any content in matrimonial listings. This helps us ensure compliance with placement rules, maintain accuracy, and adhere to prevailing laws.  </p>
          <p><b>**Duplicates Alert**:</b> If we come across duplicate profiles, we reserve the right to remove them. This ensures that your search results are clutter-free and genuine.</p>
          <p>Address Verification: To maintain trust, we may request physical address verification. Refusal to consent may result in a "Not Verified" stamp or profile deletion.
          </p>
          <h6>18. Exploring External Links and Third-Party Content </h6>
          <p>Our platform includes links to external websites, but please note that the content on those sites is solely the responsibility of their promoters. We don't recommend, authenticate, endorse, verify, or certify these websites or their content.</p>
          <p> Privacy Matters: The privacy practices of these external sites are beyond our control, so we encourage users to review their privacy statements.</p>
          <p>Other Online Services: We also want to clarify that we don't endorse or authenticate the authenticity of other online services displayed on our platform, like contests, astrology, palmistry, etc. Please exercise caution when navigating these external links, as they connect through your own IP, not ours. We advise against sharing personal information, such as usernames and profile IDs, with such websites.</p>
          <h6>19. Future Partners and Information Sharing</h6>
          <p>In the future, we might partner with other entities to offer more online services. Rest assured, we won't share your information with these partners without your consent. We recommend reviewing their privacy policies before sharing any personally identifiable information, as we cannot be held responsible for their practices.</p>
          <h6>20. Our Commitment to You</h6>
          <p>We want to be clear about our responsibilities.</p>
          <p><b>**Disclaimer of Liability**:</b> We disclaim any liabilities, warranties, or guarantees related to the platform, its contents, links, or its discontinuance. Claims for damages or loss of profits related to the platform are not sustainable against us.</p>
          <h6>21. Technical Matters and Communication</h6>
          <p>We do our best to provide a seamless experience, but sometimes technical issues may arise.
            Communication: We're not responsible for errors, interruptions, delays, or unauthorized access to user communications. Technical problems are beyond our control, and we're not liable for any injuries or damages from using our platform.</p>
          <h6>22. User Classification and Privileges</h6>
          <p>We value all our users.</p>
          <p>User Types: You may be classified as having incomplete or complete profiles, and you might be a free or paid member. We reserve the right to modify privileges, benefits, liabilities, and responsibilities for all user types as needed.</p>
          <p><b>**Free User Benefits**: </b>Benefits for free users, like the "Express Interest" functionality and viewing contact details, are determined by us.</p>
          <h6>23. Payment and Refund Policy</h6>
          <p>Here's how payments work:</p>
          <p>Non-refundable Payments: Payments for platform services are made in advance and are non-refundable. Any refunds, if granted, are at our sole discretion.</p>
          <p><b>**Terms Updates**: </b>We reserve the right to amend disclaimers and terms without notice. Continued usage of the platform constitutes acceptance of the amended terms.</p>
          <h6>24. User Responsibility and Confidentiality</h6>
          <p>Your responsibilities:</p>
          <p>Password Confidentiality: Please keep your password confidential and be responsible for activities conducted using your user identification.</p>
          <p>Online Payments: We don't store credit card, debit card, or bank account information. Online payment transactions are secure and done through trusted payment gateway platforms.</p>
          <h6>25. Platform Availability</h6>
          <p>While we aim for a smooth experience,</p>
          <p><b>**No Guarantees**:</b> We can't guarantee uninterrupted or error-free platform usage. Delays and interruptions may occur, and you acknowledge that using the platform is at your own risk.</p>
          <p>Service Changes: We may discontinue services or change their availability at any time.</p>
          <h6>26. Third-Party Payment Gateways</h6>
          <p>For transparency: </p>
          <p>Third-Party Gateways: We use third-party payment gateways. Any issues with these gateways will be communicated to users. Currency conversion charges, deductions, or errors from payment gateways are not our responsibility.</p>
          <h6>27. Protecting Our Platform</h6>
          <p>Our platform is precious</p>
          <p><b>**Proprietary Rights**:</b> We hold complete rights to the platform and services. Unauthorized copying, modifying, publishing, transmitting, distributing, displaying, or selling of proprietary information is prohibited. Infringement may lead to legal action.</p>
          <p>We're here to assist you on your matrimonial journey, and your trust and safety are our top priorities. If you have any questions or concerns, feel free to reach out to us. Happy searching! 28. Content Verification and User Responsibilities</p>
          <p>Our company, now known as Hindu Shadi Connect, upholds the right to verify the authenticity of the content posted on our platform. In certain situations, we may request that users provide supporting evidence for the information they have shared. It is essential for users to promptly furnish such evidence upon our request. Failure to do so may result in the termination of your profile or access to our platform.</p>
          <p>Additionally, the company may take legal action against users who misuse our online services, thereby negatively impacting the platform's reputation and functionality.</p>
          <h6>29. Enforcement Against Violations and Misuse</h6>
          <p>Hindu Shadi Connect retains the authority to investigate and take legal action against users who misuse our online services, all without prior notice. This includes terminating the profiles or access of those who use the platform to harm its reputation or smooth operation.
          </p>
          <h6>30. Responsibility for User-Posted Content</h6>
          <p>Our company shall not be held accountable for information posted by users on our platform, including copyrighted material, trademarks, or proprietary information. Users must obtain prior written consent from the rightful owner for such postings. In the event of infringements, users may be asked to provide evidence supporting their display. Failure to comply may lead to appropriate legal action.</p>
          <h6>31. Platform Monitoring and Content Removal</h6>
          <p>While the company is not obligated to actively monitor user activities, we reserve the right to do so electronically and to disclose information as required by law or to ensure the proper functioning of the platform. We also reserve the right to remove or decline to post information that we deem unacceptable, undesirable, or in violation of laws.</p>
          <h6>32. Platform Usage and Jurisdiction</h6>
          <p>The use of our platform and the resolution of disputes are subject to the prevailing laws in Damoh. Users are under the exclusive jurisdiction of courts located in Damoh.</p>
          <h6>34. Restrictions on Accounts</h6>
          <p>In the event of evidence or complaints of misconduct against a user, the company may impose account restrictions, such as limiting contact with other members. Such restrictions may also apply to users who create duplicate profiles.</p>
          <h6>35. Membership Duration and Calculation</h6>
          <p>For services with specified time periods in months, one month is considered to be equivalent to 30 days. For services with longer durations, the total duration in days is calculated by multiplying the number of months by 30.</p>
          <h6>36. Profile Information Guidelines</h6>
          <p>Members are strictly prohibited from sharing personal information, such as contact details, in fields other than those designated for such information. The company reserves the right to remove any information placed incorrectly.</p>
          <h6>37. Consent for Communications</h6>
          <p>By utilizing our platform, users provide consent to receive the following communications: Membership Emails: Benefits of Paid Membership Important Notifications: Alerts related to platform usage Membership SMS/Calls: Conveying paid membership benefits .</p>
          <h6>40. Assistance in Finding Candidates</h6>
          <p>Users of Hindu Shadi Connect VIP services allow the company to assist in finding suitable candidates as per their instructions via various means.
          </p>
          <h6>41. Duplicate Profile Prohibition</h6>
          <p>Creating duplicate profiles is strictly prohibited, and Info Edge reserves the right to delete, block, or restrict access to such profiles.
          </p>
          <h6>42. Membership Renewal and Contact Views</h6>
          <p>Unused "Contact Views" are carried forward during renewal, except when renewing to the 'Basic' plan. The determination is based on purchases made before or after the renewal date.</p>
          <h6>43. Profile Visibility and Search Engines</h6>
          <p>Profile information, including "name," is visible to other members and is indexed by search engines such as Google, Bing, and Yahoo.</p>
          <h6>44. Platform Feature Usage Rules</h6>
          <p>Certain platform features are subject to specific rules. Unverified phone numbers may restrict certain actions, so users should verify their phone numbers for full access.</p>
          <h6>45. Non-Transferable Memberships</h6>
          <p>Paid memberships are non-transferable and are valid only for the individual who purchased them.
          </p>
          <h6>46. Quota Usage for Accessing Contacts</h6>
          <p>Users accessing the contacts of other members may use their quota of "View Contacts" over time.</p>
          <h6>47. Profile deletion and membership</h6>
          <p>A paid membership is associated with the profile. Profile deletion results in membership expiration with no possibility of reactivation.</p>
          <h6>48. Irreversible Profile Deletion</h6>
          <p>Profiles deleted by users cannot be retrieved under any circumstances</p>
          <h6>49. Referral Coupons</h6>
          <p>Coupons generated for referrals have an expiration date and specific terms of use.</p>
          <h6>51. Company Performance Obligations</h6>
          <p>The company provides access to its online portal during the subscription period. Usage is subject to terms and does not imply additional obligations.  </p>
          <h6>52. Profile Housekeeping</h6>
          <p>Profiles inactive for 12 months undergo housekeeping, resulting in the removal of associated data.</p>
          <h6>53. Pricing and Contact Views</h6>
          <p>Pricing and contact views are prorated, and plan upgrades are valid until the expiration of the original plan.</p>

          <div>
            <h5 className="py-3" style={{ color: "#F00D04" }}>Restriction / Prohibitions -</h5>
            <p>The following actions are strictly prohibited, as they constitute misuse of the platform:</p>
            <div>
              <h6>(i) Unauthorized Usage of Content</h6>
              <p>Users are not allowed to copy, modify, sell, distribute, or exploit any content, data, or information available on the platform for purposes inconsistent with the platform's intended use and terms of service. Prohibited activities include:</p>
              <p>(a) commercial use, such as creating alternate databases or extending platform access to third parties without written consent.</p>
              <p>(b) engaging in business activities that compete with the company. (c) Seeking fees under false pretenses</p>
              <p>(d) Approaching candidates for reasons other than specific vacancies</p>
              <p>(e) Sharing access with non-contracted individuals;</p>
              <p>(f) Reselling company products and services</p>
            </div>
            <h6>(ii) Automated Access and Extraction</h6>
            <p>Using automated programs or systems to access, copy, monitor, or download data from the platform, including profiles and contact details, is prohibited. This includes actions like scraping and redirecting messages.
            </p>
            <h6>(iii) Unauthorized Access</h6>
            <p>Attempting to gain unauthorized access to the platform, servers, websites, or computer systems of the company or other third parties is not allowed. This includes hacking or password mining.</p>
            <h6>(iv) Modifying Services</h6>
            <p>Modifying the appearance or functions of the platform services without explicit written consent from the company is prohibited.</p>
            <h6>(v) Unauthorized Interfaces</h6>
            <p>Accessing the platform through interfaces other than those provided by the company is not allowed.</p>
            <h6>(vi) Security Measures</h6>
            <p>Attempting to breach security measures or disrupt the use of the platform is strictly prohibited. This includes probing system vulnerabilities.</p>
            <h6>(vii) Unauthorized Information Extraction</h6>
            <p>Scraping or extracting information from the platform to offer similar products or services that compete with the company is not allowed.</p>
            <h6>(viii) Reverse Engineering</h6>
            <p>Reverse engineering or deciphering the source code of the platform or related technology is prohibited.</p>
            <h6>(ix) Circumventing Protections</h6>
            <p>Circumventing technological protections or measures to protect platform content is not allowed.</p>
            <h6>(x) Service Limits</h6>
            <p>Trying to bypass or circumvent service limits is prohibited.</p>
            <h6>  (xi) Interference</h6>
            <p>Interfering with the use of the platform by other users or disrupting computer networks connected to the platform is not allowed.</p>
            <h6>(xii) Unauthorized Automation</h6>
            <p>Using automated programs, scripts, or software to access, copy, or modify platform data without written consent is prohibited.</p>
            <h6>(xiii) Impersonation</h6>
            <p>Impersonating others or misrepresenting affiliation with any entity is not allowed.</p>
            <h6>(xiv) Manipulation of User Information</h6>
            <p>Manipulating user information to hide an identity is prohibited.
            </p>
            <h6>(xv) Harassment</h6>
            <p>Stalking, threatening, or harassing other users is strictly prohibited.</p>
            <h6>(xvi) Overloading Infrastructure</h6>
            <p>Causing an unreasonable load on the platform infrastructure is not allowed.</p>
            <h6>(xvii) Simulating Platform Appearance</h6>
            <p>Simulating the appearance or function of the platform without permission is prohibited.</p>
            <h6>(xviii) Spamming</h6>
            <p>Spamming the platform, company, or users is not allowed.</p>
            <h6>(xix) Unauthorized Communication</h6>
            <p>Sending unrelated marketing messages, offensive messages, or unsolicited communications is prohibited</p>
            <h6>(xx) Inappropriate Material</h6>
            <p>Posting material that violates laws, infringes rights, contains viruses, or promotes harmful content is strictly prohibited.</p>
          </div>

          <h4 className="py-3">- Hindu Shadi Connect</h4>
        </div >
        <Footer />
      </Row>

    </Container >
  )

 
};

export default TermsCondition;
