import axios from "axios";
import Constant from "./Constant";

const axiosInstance = axios.create({
    baseURL: `${Constant.appBaseUrl}/api/`,
    // timeout: 10000,
});

export async function UsersApi(data) {
    var response = await axiosInstance.post('web-site/hindu-users', data)
    return response;
}

// Get User Data according to id
export async function getProfileById(id) {
    var response = await axiosInstance.get(`hindu_app/get-profile/${id}`)
    return response;
}
export async function login(data) {
    var response = await axiosInstance.post("hindu_app/hindu_auth/login", data);
    return response;
}
export async function authorizeMe(token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}