import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

const MemberLogin = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container-fluid">
            <div className="row p-0"><Header /></div>
            <div className="row topDivider">
                <p className={`PageTitleText light p-2 m-0 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30'}`}>Member Login</p>
            </div>
            <div className={`row py-3 ${window.innerWidth <= 768 ? 'px-2 text-center' : 'px-5'}`} style={{ borderBottom: "1px solid #d1d1d1" }}>
                <h5 className="py-2">Member Login</h5>
                <h6 className="py-1">**Title URL:**</h6>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`} >1. Welcome to HinduShadiConnect.com – Your Trusted Destination for Hindu Matrimonial Connections!</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`} >2. Discover a World of Hindu Matrimonials as You Log into HinduShadiConnect.com</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`} >3. Embark on Your Journey to Find Your Perfect Hindu Life Partner with HinduShadiConnect.com's User-Friendly Login</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`} >4. Join HinduShadiConnect.com Today and Start Your Quest for Hindu Matrimonial Bliss!</p>
                <h6 className="py-1">**Description URL:**</h6>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`}>1. Unlock Your Path to Happily Ever After with Hindu Matrimonials - Member Login on HinduShadiConnect.com!</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`}>2. Connect with Your Potential Life Partner Today - Access the Member Login on HinduShadiConnect.com and Explore Hindu Matrimonials with Ease. Begin Your Search for Love and Companionship Now!</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`}>3. Discover Your Perfect Partner with Hindu Matrimonials - Member Login on HinduShadiConnect.com!</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`}>4. Start Connecting Today - Access the Member Login, Explore Hindu Matrimonials, and Find Love Now!</p>
                <h6 className="py-3">**Keywords:**</h6>
                <h6>Hindu Matrimonials</h6>
                <h6>Discover Your Perfect Partner</h6>
                <h6>Member Login</h6>
                <h6><a href="https://hindushadipatrika.com/">HinduShadiConnect.com</a></h6>
                <h6>Start Connecting Today</h6>
                <h6>Explore Hindu Matrimonials</h6>
                <h6>Find Love Now</h6>
                <h6>Matrimony Services</h6>
            </div>
            <Footer />
        </div>
    )
};

export default MemberLogin;
