import React from "react";
import logo from "../assets/images/Logo.png"
import { Button, Col, Container, Row } from "reactstrap";
import arrowright from "../assets/images/ArroeRight.svg";
import arrow from "../assets/images/arrow.svg";
import ReactPlayer from 'react-player'
import socialIcon from '../assets/images/social-care 1.png'
import resumeIcon from '../assets/images/resume 1.png'
import filterIcon from '../assets/images/filter 1.png'
import handShake from '../assets/images/handshake 1.png'
import shield from '../assets/images/shield 1.png'
import regImg from "../assets/images/registrationImg.png";
import verifyImg from "../assets/images/verificationImg.png";
import searchImg from "../assets/images/seachImg.png";
import MeetImg from "../assets/images/MeetImg.png";
import shareIcon from '../assets/images/shareIcon.png';
import demo from '../assets/images/demoImg.jpeg';
import male from '../assets/images/male.png';
import Slider from "react-slick";
import lang from "../assets/images/Language.png";
import star from "../assets/images/Star 1.svg";
import Footer from "../Pages/Footer";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import iphone from '../assets/images/iphone.png'
import share from '../assets/images/shareIcon.png'
import download_img from '../assets/images/download_img.png'
import play from '../assets/images/google-play 1.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FemaleSlider from '../Components/OurRegisterSlider/FemaleSlider';
import MaleSlider from '../Components/OurRegisterSlider/MaleSlider';
import { useNavigate } from 'react-router-dom';


const Home = () => {

    const navigate = useNavigate();

    const handleDownloadClick = () => {
        window.open('#/download', '_blank');
    };


    return (
        <div>
            <Container fluid>
                <Row className="bannerStyle d-flex justify-content-center">
                    <Col className="p-0">
                        <a href="https://hindushadiConnect.com/"><img className="logoImg" src={logo} alt="" /></a>
                    </Col>
                    <Col className="p-2 text-end">
                        <img src={lang} alt="" className={`langImg ${window.innerWidth <= 768 ? 'pe-2' : 'pe-4'}`} />
                        <a href="https://mobile.hindushadiconnect.com/#/signin"><Button className="logInBtn">Log In</Button></a>
                    </Col>
                    <Row className="m-0 text-center" style={{ height: window.innerWidth <= 767 ? "120px" : "170px" }}>
                        <div className="mainHeading">हिन्दू समाज का सबसे विश्वसनीय Online परिचय App</div>
                        <div className="secHeading">हिन्दुओं की शादी हिन्दुओं से...</div>
                    </Row>
                    <div className="row secondaryBg registerSec p-0">
                        <div className="col-lg-4 displayCenterProp freeRegistr">
                            <Link to="https://mobile.hindushadiconnect.com/#/signin">
                                <Button className={`RegisterBtn ${window.innerWidth <= 768 ? 'fs-20' : 'fs-24'}`}><ArrowBackIcon style={{ marginRight: "10px" }} />Login Now</Button></Link>
                        </div>
                        <div className="col-lg-4 text-center journeySec d-flex flex-column justify-content-center">
                            <p className={`m-0 primaryColor  ${window.innerWidth <= 768 ? 'fs-26' : 'fs-32'}`}>Start Your Journey</p>
                            <p className={`m-0   ${window.innerWidth <= 768 ? 'fs-20' : 'fs-24'}`}>By Just One Simple Step</p>
                        </div>
                       
                        <div className="col-lg-4 displayCenterProp freeRegistr">
                            <Link to="https://mobile.hindushadiconnect.com/#/signin">
                                <Button className={`RegisterBtn ${window.innerWidth <= 768 ? 'fs-20' : 'fs-24'}`} >Register For Free <ArrowForwardIcon /></Button></Link>
                        </div>
                    </div>
                </Row>
                <div className="row p-4 primaryBg">
                    <div className={`  ${window.innerWidth <= 768 ? 'col-sm-12 text-center p-0' : 'col-lg-6 ps-3'}`}>
                        <p className="light text-center py-2 m-0 HindiTextStyle">हिन्दू शादी कनेक्ट क्या है ?</p>
                        <p className={`light mb-4   ${window.innerWidth <= 768 ? 'fs-16 text-center' : 'fs-20'}`}>
                            We are thrilled to share with you the exciting news about the launch of "Hindu Shadi Connect." This exceptional platform has been created with the primary aim of helping Hindu young men and women discover their perfect life partners. Introducing the Hindu Shadi Connect App, a unique and tailored solution designed exclusively for the Hindu community.
                        </p>
                        <Link to="/about"> <Button className="ReadBtn">Read More</Button></Link>
                    </div>
                    <div className="col-lg-6 col-sm-12 d-flex flex-column align-items-center">
                        <p className={`light m-0 ${window.innerWidth <= 768 ? 'fs-24 text-center py-3' : 'fs-32 py-2'}`}>How To Register ??</p>
                        {/* <VideoSlider /> */}
                        <ReactPlayer url={'https://youtube.com/shorts/hHXKyKIequk?si=a0L7z_nAXxYoINH1'} height="233.474px" width="370px" controls={true} />
                    </div>
                </div>
                <div className="row py-4">
                    <div className="reasonSecRgt">
                        <div className="row reasonSec" >
                            <div className={`d-flex flex-column align-items-center ${window.innerWidth <= 768 ? 'my-1 justify-content-center' : 'my-4'}`} style={{ height: "120px" }}>
                                <span className={`fw-500 primaryColor ${window.innerWidth <= 768 ? 'fs-28 text-center' : 'fs-48'}`}>Why Hindu Shadi Connect</span>
                                <span className={` ${window.innerWidth <= 768 ? 'fs-18 text-center' : 'fs-28'}`} style={{ color: "#535353" }}>You have various reason to choose us</span>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className='row'>
                                    <div className={` ${window.innerWidth <= 768 ? 'col-3 pt-2' : 'col-4 text-end'}`}>
                                        <img src={socialIcon} alt="" />
                                    </div>
                                    <div className={`col mb-3 ${window.innerWidth <= 768 ? 'p-0' : 'ps-5'}`}>
                                        <p className={`my-2 fw-500 ${window.innerWidth <= 768 ? 'fs-20' : 'fs-30 '}`}>हिन्दुओं के द्वारा हिन्दुओं के लिये</p>
                                        <p className={` ${window.innerWidth <= 768 ? 'fs-15' : 'fs-18'}`}>यह हिन्दुओं के द्वारा हिन्दुओं के लिये बनाया गया app है ,
                                            <br /> इसलिए बहुत ही Trustfull App है |</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={` ${window.innerWidth <= 768 ? 'col-3 pt-2' : 'col-4 text-end'}`}>
                                        <img src={resumeIcon} alt="" />
                                    </div>
                                    <div className={`col mb-3 ${window.innerWidth <= 768 ? 'p-0' : 'ps-5'}`}>
                                        <p className={`my-2 fw-500 ${window.innerWidth <= 768 ? 'fs-20' : 'fs-30 '}`}>सिर्फ Verified हिन्दु बायोडेटा</p>
                                        <p className={` ${window.innerWidth <= 768 ? 'fs-15' : 'fs-18'}`}>
                                            इस App पर आपको केवल हिन्दु समाज के
                                            <br /> Verified बयोडेटा ही िमलेंगे | </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={` ${window.innerWidth <= 768 ? 'col-3 pt-2' : 'col-4 text-end'}`}>
                                        <img src={filterIcon} alt="" />
                                    </div>
                                    <div className={`col mb-3 ${window.innerWidth <= 768 ? 'p-0' : 'ps-5'}`}>
                                        <p className={`my-2 fw-500 ${window.innerWidth <= 768 ? 'fs-20' : 'fs-30 '}`}>Filter सुविधा </p>
                                        <p className={` ${window.innerWidth <= 768 ? 'fs-15' : 'fs-18'}`}>इसमें आप Age, Area, Profession, के <br /> आकॉर्डिंग हिन्दु बायोडाटा देख सकते है |</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="row">
                                    <div className={` ${window.innerWidth <= 768 ? 'col-3 pt-2' : 'col-2 text-end'}`}>
                                        <img src={handShake} alt="" />
                                    </div>
                                    <div className={`col mb-3 ${window.innerWidth <= 768 ? 'p-0' : 'ps-5'}`}>
                                        <p className={`my-2 fw-500 ${window.innerWidth <= 768 ? 'fs-20' : 'fs-30 '}`}>सकल हिन्दु समाज एकता</p>
                                        <p className={` ${window.innerWidth <= 768 ? 'fs-15' : 'fs-18'}`}>हमारा उद्देश्य सकल हिन्दु समाज को जोड़ <br /> कर एक प्लेटफार्म पर लाना है |</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={` ${window.innerWidth <= 768 ? 'col-3 pt-2' : 'col-2 text-end'}`}>
                                        <img src={shield} alt="" />
                                    </div>
                                    <div className={`col mb-3 ${window.innerWidth <= 768 ? 'p-0' : 'ps-5'}`}>
                                        <p className={`my-2 fw-500 ${window.innerWidth <= 768 ? 'fs-20' : 'fs-30 '}`}>विश्वसनीय app</p>
                                        <p className={` ${window.innerWidth <= 768 ? 'fs-15' : 'fs-18'}`}>यह हिन्दुओं का सबसे विश्वसनीय App है |</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row primaryBg sliderSec text-center py-4">
                    <>
                        <p className={`light ${window.innerWidth <= 768 ? 'fs-30 text-center' : 'fs-48'}`}>Our Registered Males</p>
                        <MaleSlider />
                    </>
                    <div>
                        <p className={`light ${window.innerWidth <= 768 ? 'fs-30 text-center' : 'fs-48'}`}>Our Registered Females</p>
                        <FemaleSlider />
                    </div>
                </div>

                <div className="py-4">
                    <div className={`d-flex flex-column align-items-center  ${window.innerWidth <= 768 ? 'my-2 justify-content-center' : 'my-4'}`} style={{ height: "120px" }}>
                        <span className={`primaryColor fw-500 ${window.innerWidth <= 768 ? 'fs-32 text-center' : 'fs-48'}`}>Easy Registration</span>
                        <span className={`${window.innerWidth <= 768 ? 'fs-18 text-center' : 'fs-24'}`}>Find Your Perfect Match In 4 Easy Steps</span>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center text-center">
                        <div className="col-lg-2 col-md-5 col-sm-12">
                            <img className="my-3" src={regImg} alt="" style={{ marginRight: "23px" }} />
                            <div><Button className="regBtns" outline>Registration</Button></div>
                        </div>
                        <div className="col-1" style={{ alignSelf: "center" }}>
                            {window.innerWidth <= 600 ? null : <img src={arrow} alt="" />}
                        </div>
                        <div className="col-lg-2 col-md-5  col-sm-12">
                            <img className="my-3" src={verifyImg} alt="" style={{ marginRight: "35px" }} />
                            <div>  <Button className="regBtns" outline>Verification</Button> </div>
                        </div>
                        <div className="col-1" style={{ alignSelf: "center" }}>
                            {window.innerWidth <= 600 ? null : <img src={arrow} alt="" />}
                        </div>
                        <div className="col-lg-2 col-md-5  col-sm-12">
                            <img src={searchImg} alt="" />
                            <div><Button className="regBtns" outline>Search</Button></div>
                        </div>
                        <div className="col-1" style={{ alignSelf: "center" }}>
                            {window.innerWidth <= 600 ? null : <img src={arrow} alt="" />}
                        </div>
                        {/* <div className="col-lg-2 col-md-5  col-sm-12">
                            <img src={MeetImg} alt="" />
                            <div><Button className="regBtns" outline>Meet</Button></div>
                        </div> */}
                    </div>
                </div>

                <div className="row displayCenterProp whatsappStyle py-3">
                    <div className="col-lg-6 col-sm-12 text-center">
                        <p className={` ${window.innerWidth <= 768 ? 'fs-22 m-0' : 'fs-38'}`} style={{ fontFamily: "Laila", fontWeight: "700" }}>WhatsApp Group से जुडने के लिये</p>
                        <div className={`displayCenterProp numStyle light ${window.innerWidth <= 768 ? 'fs-20 my-2' : 'fs-38 my-4 '}`}>
                            <span className="px-2 primaryBg" style={{ borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", borderRight: '2px solid' }}><a href="tel:+9009992462" className="numberlinkstyle">  9009992462 </a>
                                {/* <span>|</span> */}
                            </span>
                            <span className="px-2 primaryBg" style={{ borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}>
                                <a href="tel:+9009992464" className="numberlinkstyle">9009992464</a>
                            </span></div>
                        <p className={` ${window.innerWidth <= 768 ? 'fs-24 m-0' : 'fs-38'}`} style={{ fontFamily: "Laila", fontWeight: "600" }}>WhatsApp पर “नमस्ते” लिखकर भेजें</p>
                        <Button className={`btn whatsappBtn px-3 ${window.innerWidth <= 768 ? 'my-2' : 'my-4'}`}>Click here to join <WhatsAppIcon className="fs-38" /> </Button>
                    </div>
                    <div className="col-lg-6 col-sm-12 text-center"><img src={iphone} className="phoneImgHeight" /></div>
                </div>
                

<div class="row download_page align-items-center">
                        <div class="col-md-6 how-img">
                            <img className="DownloadSection img-fluid" src={download_img}  alt=""/>
                        </div>
                        <div class="col-md-6">
                        <p className="text-white"
        
        style={{ fontFamily: 'Laila', fontWeight: '700', fontSize: '34px' , }}
      >
        Hindu Shadi Connect
      </p>
      <p className="text-white"
        
        style={{ fontFamily: 'Laila', fontWeight: '600', fontSize: '27px' }}

      >
        India's No. 1 Shadi App
      </p>
      <p className="text-white"
       
      >
        Providing our services in All over India
      </p>
      <button
      style={{fontSize: "18px"}}
          className="border border-white rounded-0 px-4 text-white btn-1"
          onClick={handleDownloadClick}
        >
          Click Here To Download Our App
        </button>
                        </div>
                    </div>
                {/* Footer Section */}
                <Footer />
            </Container>
            
        </div >
    );
};

export default Home;
