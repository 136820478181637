import React, { useEffect } from "react";
import { Container, Row } from "reactstrap";
import Header from "./Header";
import Footer from "./Footer";

const LiveSupport = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container-fluid">
            <div className="row p-0"><Header /></div>
            <div className="row topDivider">
                <p className={`PageTitleText light p-2 m-0 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30'}`}>24/7 Live Support</p>
            </div>
            <div className={`row py-3 ${window.innerWidth <= 768 ? 'px-4 text-center' : 'px-5'}`} style={{ borderBottom: "1px solid #d1d1d1" }}>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Always Here for You: 24/7 Support Tailored to Your Needs</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>At Hindu Shadi Connect, we take pride in being more than just a service provider; we're your dedicated partner in your journey. That's why we offer round-the-clock, 24/7 support to ensure that your experience with us is seamless and stress-free, no matter the time of day.</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Why Choose Our 24/7 Support?</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Immediate Assistance: Life doesn't adhere to a 9-to-5 schedule, and neither do we. Our 24/7 support team is here to assist you at any hour of the day or night. Whether you're a night owl or an early riser, rest assured that help is just a message or call away.</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>
                    Personalized Solutions: We understand that every query is unique. Our skilled support professionals are trained to listen attentively and offer tailored solutions to address your specific needs. Your satisfaction is our priority.</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Quick Resolutions: We know that your time is precious. That's why we strive to resolve your issues swiftly and efficiently. Our goal is to get you back to enjoying our services with minimal disruption.</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Comprehensive Knowledge: Our support team is well-versed in our products and services. From troubleshooting technical issues to guiding you through processes, we've got you covered with accurate information and insights.</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}> How to Reach Us</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Getting in touch with our 24/7 support is a breeze.</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Live Chat: Visit our website and engage in a live chat with one of our support representatives. They're ready to assist you in real time, answering your questions and addressing your concerns</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Phone Support: If you prefer a more direct approach, our phone lines are open 24/7. Just give us a call, and a friendly voice will be there to guide you through any issues you're facing.</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Email: Shoot us an email with your query, and we'll respond promptly with the information you need.</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Our Commitment to You</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>At Hindu Shadi Connect, we're not satisfied until you are. Our 24/7 support is a reflection of our dedication to delivering exceptional customer experiences. Whether you have a simple question or encounter a complex challenge, we're here to ensure that you receive the assistance you deserve, whenever you need it.</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Your journey matters to us, and our 24/7 support is a testament to that commitment. Let us be your reliable partner in every step you take with us.</p>
                <p className={`p-0 ${window.innerWidth <= 768 ? 'fs-14' : 'fs-16'}`}>Feel free to reach out whenever you need assistance; we're just a click or call away.</p>
            </div>
            <Footer />
        </div>
    )
};

export default LiveSupport;
