import React from "react";
import { Navigate, Route, Routes } from "react-router";
import Home from "./Pubilc/Home";
import './theme/Style.css'
import './theme/Common.css'
import './theme/variables.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import TermsCondition from "./Pages/TermsCondition";
import RefundPolicy from "./Pages/RefundPolicy";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import LiveSupport from "./Pages/LiveSupport";
import MemberLogin from "./Pages/MemberLogin";
import { Grid } from "@mui/material";
import DownloadApp from "./Pages/DownloadApp";


function App() {

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/#/home' element={<Home />} />
        <Route exact path='/about' element={< About />}></Route>
        <Route exact path='/contact' element={< Contact />}></Route>
        <Route exact path='/terms' element={< TermsCondition />}></Route>
        <Route exact path='/refund-policy' element={< RefundPolicy />}></Route>
        <Route exact path='/privacy-policy' element={< PrivacyPolicy />}></Route>
        <Route exact path='/member-login' element={< MemberLogin />}></Route>
        <Route exact path='/live-support' element={< LiveSupport />}></Route>
        <Route exact path='/download' element={< DownloadApp />}></Route>
      </Routes>
    </div>
  );
}

export default App;
