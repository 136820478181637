import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="container-fluid">
            <div className="row p-0"><Header /></div>
            <div className="row topDivider">
                <p className={`PageTitleText light p-2 m-0 ${window.innerWidth <= 768 ? 'fs-24' : 'fs-30'}`}>About Us</p>
            </div>
            <div className={`row py-3 ${window.innerWidth <= 768 ? 'px-2 text-center' : 'px-5'}`} style={{ borderBottom: "1px solid #d1d1d1" }}>
                <h4 className="py-3">Namaste!</h4>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`}>This organisation is working under Hindu Shadi Connect.</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`}>We are thrilled to share with you the exciting news about the launch of "Hindu Shadi Connect."
                    This exceptional platform has been created with the primary aim of helping Hindu young men
                    and women discover their perfect life partners. Introducing the Hindu Shadi Connect App, a
                    unique and tailored solution designed exclusively for the Hindu community. This state-of-the-art
                    app will be your gateway to finding your life partner.</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-20'}`}>The Hindu Shadi Connect app has been thoughtfully developed, considering a wide range of
                    partner preferences that individuals may have while embarking on the journey to find a life
                    partner. Our app is meticulously designed to deliver the best results, ensuring a harmonious
                    match with your life companion. We take pride in our diverse member base, which
                    encompasses various sects and subsects within the Hindu community, and we also welcome
                    profile registrations from around the world. Your privacy is of utmost importance to us, and we
                    are committed to safeguarding it.
                </p>
                <h5 className="pb-2">Key Features of Hindu Shadi Connect:</h5>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-18'}`}>1.  **Privacy First:** We provide a secure and confidential environment to protect your privacy.</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-18'}`}>2. **User-Friendly:** Our intuitive interface makes partner searching effortless and enjoyable.</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-18'}`}>3. **Smart List Management:** Easily curate a list of your preferred candidates.</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-18'}`}>4. **Customized Filters:** Discover potential matches aligned with your preferences.</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-18'}`}>5. **Efficiency:** Save valuable time and resources with our efficient solution.</p>
                <p className={`pe-3 ${window.innerWidth <= 768 ? 'fs-16' : 'fs-18'}`}>We kindly request each one of you to support this noble initiative aimed at strengthening the
                    bonds within the Hindu community and society as a whole. If you have eligible Hindu young
                    men and women within your family or circle of friends, we encourage you to guide them towards
                    this opportunity. Let's come together to strengthen our community ties through this endeavor.</p>
                <h4>-Hindu Shadi Connect</h4>
            </div>
            <Footer />
        </div>
    )
};

export default About;
