import React, { useEffect, useState } from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Button } from "@mui/material";
import star from "../../assets/images/Star 1.svg"
import shareIcon from '../../assets/images/shareIcon.png';
import Constant from "../../Constant";
import { Image } from "react-bootstrap";
import demo from "../../assets/images/demo profile.png"
import { UsersApi } from "../../Service";

const FemaleSlider = () => {
    const [femaleUserData, setFemaleUserData] = useState();

    useEffect(() => {
        getFemaleUsers("");
    }, []);

    const getFemaleUsers = async (data) => {
        let obj = {
            "gender": "Female"
        };
        let res = await UsersApi(obj);
        if (res?.data?.status) {
            setFemaleUserData(res?.data?.data);
        }
    };

    const responsive = {
        0: { items: 1, itemsFit: 'contain', },
        568: { items: 2, itemsFit: 'contain', },
        1024: { items: 4, itemsFit: 'contain', },
    };
    const items = femaleUserData?.map((el) => (
        <div key={el}>
            <div style={{ position: "relative" }}>
                <div>
                    {el?.hindu_profiles?.length > 0 ? (
                        el?.hindu_profiles?.map((profile, index) =>
                            profile?.type === 'profile' && profile?.order_number === 10 ? (
                                <div key={index}>
                                    <Image
                                        style={{ height: "400px", width: "301px", objectFit: "fill", borderRadius: "8px" }}
                                        src={`${Constant?.appBaseUrl}/${profile?.folder}/${profile?.name}`}
                                    />
                                </div>
                            ) : null
                        )
                    ) : (
                        <div>
                            <Image
                                style={{ width: "150px", height: "150px", objectFit: "fill", marginRight: "10px" }}
                                src={demo}
                            />
                        </div>
                    )}
                </div>
                <div className="sliderSecStyle">
                    <div className="row p-2">
                        <div className="col p-0">
                            <p className="fs-12 m-0 text-start ps-2">{el?.nameEnglish} | {el?._age} Yrs</p>
                            <p className="fs-12 m-0 text-start ps-2">{el?.third_formSectEnglish} | Unmarried</p>
                        </div>
                        <div className="col p-0">
                            <Button className="sendBtn"><img src={shareIcon} height="15" className="pe-2" />Send Interest</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col p-0">
                            <p className="primaryColor fs-12 m-0">Profession</p>
                            <p className="fs-12">Engineer</p>
                        </div>
                        <div className="col p-0">
                            <p className="primaryColor fs-12 d-flex justify-content-center m-0"><img src={star} height={15} /> Manglikta</p>
                            <p className="fs-12">Don’t Know</p>
                        </div>
                        <div className="col p-0 ">
                            <p className="primaryColor fs-12 m-0">Address</p>
                            <p className="fs-12">{el?.second_formStateEnglish}</p>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    ));
    return (
        <AliceCarousel
            items={items}
            responsive={responsive}
            controlsStrategy="alternate"
            autoPlay
            autoPlayStrategy="none"
            autoPlayInterval={1500}
            animationDuration={1500}
            animationType="slide"
            infinite
            touchTracking={false}
            disableButtonsControls
            disableDotsControls
        />
    )
};

export default FemaleSlider;
