import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import logo from "../assets/images/Logo.png"
import lang from "../assets/images/languageIcon.png";

const Header = () => {
    return (
        <Container fluid>
            <Row className="pb-3">
                <Col className="p-0"><a href="https://hindushadiconnect.com"><img className="logoImg" src={logo} alt="" /></a></Col>
                <Col className="p-2 d-flex justify-content-end align-items-center">
                    <img src={lang} alt="" height="30" className={`langImg ${window.innerWidth <= 768 ? 'pe-2' : 'pe-4'}`} />
                    <a href="https://mobile.hindushadiconnect.com/#/signin"> <Button className="subSecLoginBtn">Log In</Button></a>
                </Col>
            </Row>
        </Container>
    )
};

export default Header;
